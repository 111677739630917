import i18n from '@/lang/i18n';

export const LONGVITY = {
    LONGVITY_1: '30',
    LONGVITY_2: '60',
    LONGVITY_3: '90'
}
export const CASBEE_DATA = {
    wood:[
        { name: i18n.t('casbee.option_wooden'), value: '30', number: 6.13},
        { name: i18n.t('casbee.option_wooden'), value: '60', number: 3.06},
        { name: i18n.t('casbee.option_wooden'), value: '90', number: 2.04},
    ],
    SType:[
        { name: i18n.t('casbee.option_steel_construction'), value: '30', number: 13.56},
        { name: i18n.t('casbee.option_steel_construction'), value: '60', number: 6.78},
        { name: i18n.t('casbee.option_steel_construction'), value: '90', number: 4.52}
    ],
    RCType:[
        { name: i18n.t('casbee.option_rc_construction'), value: '30', number: 13.28},
        { name: i18n.t('casbee.option_rc_construction'), value: '60', number: 6.64},
        { name: i18n.t('casbee.option_rc_construction'), value: '90', number:4.43}
    ]
}
export const CO2X_DISCOUNT_DATA = {
    wood:[
        { name: i18n.t('casbee.option_blank'), value: 0, source:'', number: 0},
        { name: i18n.t('casbee.option_cement_foundation_concrete'), value: 1, source:i18n.t('casbee.option_cement_foundation_concrete'), number: 0.04},
        { name: i18n.t('casbee.option_use_majority_wood'), value: 2, source:i18n.t('casbee.option_use_majority_wood'), number: 0.02},
        { name: i18n.t('casbee.option_use_almost_wood'), value: 3, source:i18n.t('casbee.option_use_almost_wood'), number: 0.04}
    ],
    SType: [
        { name: i18n.t('casbee.option_blank'), value: 0, source:'', number: 0},
        { name: i18n.t('casbee.option_cement_foundation_concrete'), value: 1, source:i18n.t('casbee.option_cement_foundation_concrete'), number: 0.04},
        { name: i18n.t('casbee.option_lightweigh_steel'), value: 2, source:i18n.t('casbee.option_lightweigh_steel'), number: 0.09},
    ],
    RCType: [
        { name: i18n.t('casbee.option_blank'), value: 0, source:'', number: 0},
        { name: i18n.t('casbee.option_cement_foundation_concrete'), value: 1, source:i18n.t('casbee.option_cement_foundation_concrete'), number: 0.03},
        { name: i18n.t('casbee.option_cement_upper_frame_concrete'), value: 2, source:i18n.t('casbee.option_cement_upper_frame_concrete'), number: 0.07},
    ]
}
export const INPUT_DATA = {
    non_equipment: i18n.t('casbee.radio_select_no_solar_power'),
    equipment: i18n.t('casbee.radio_select_solar_power')
}
